.text-danger {
  color: $vermelho;
}

.text-success {
  color: $cor-marca2;
}

.text-primary {
  color: $cor-marca1;
}

.text-warning {
  color: $amarelo;
}

.text-info {
  color: $azul;
}

.text-secondary {
  color: $cinza-3;
}

.text-small {
  font-size: 10px;
}

.font-weight-500 {
  font-weight: 500;
}
