$displays-inline: inline, inline-block, inline-grid, inline-flex;
$displays: none, block, flex, grid;

@each $type in $displays-inline {
  .d-inline-#{$type} {
    display: #{$type} !important;
  }
}

@each $type in $displays {
  .d-#{$type} {
    display: #{$type} !important;
  }
}
