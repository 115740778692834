input[type='radio'],
input[type='range'] {
  box-shadow: none;
}

input,
select,
textarea,
time {
  width: 100%;
  padding: 12px;
  background: $branco;
  border: 2px solid $escala-cinza-3;
  border-radius: 3px;
  margin: 0 5px auto auto;
  font-weight: 400;
  color: $cor-marca1;
  outline: none;
  box-shadow: 0 0 2px 0 rgba(34, 66, 108, 0.15);

  &::placeholder {
    font-size: 15px;
    color: $cinza-3;
  }

  &:disabled {
    background: $cinza-1;
    color: rgb(82, 97, 117);;
    box-shadow: 0 0 6px 0 rgba(34, 66, 108, 0.08);
    cursor: not-allowed !important;
  }

  &:focus {
    border: 2px solid $cor-marca3;
  }
}

textarea {
  max-width: 100%;
  min-width: 4em;
}

select {
  background: url(/assets/images/icones/arrowUnfoldMore.png) no-repeat right #fff;
  -webkit-appearance: none;
}
