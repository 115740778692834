.ma {
  margin: auto;
}

@for $margin from 0 through 100 {
  .mt-#{$margin} {
    margin-top: #{$margin}px;
  }

  .ml-#{$margin} {
    margin-left: #{$margin}px;
  }

  .mr-#{$margin} {
    margin-right: #{$margin}px;
  }

  .mb-#{$margin} {
    margin-bottom: #{$margin}px;
  }
}
