.swal2-confirm.swal2-styled,
.swal2-deny.swal2-styled,
.swal2-popup,
.swal2-cancel.swal2-styled {
  border-radius: 3px !important;
  border: none !important;
}

.swal2-confirm.swal2-styled {
  background-color: $cor-marca1 !important;
}

.swal2-deny.swal2-styled {
  background-color: $vermelho !important;
}

.swal2-cancel.swal2-styled {
  background-color: $cor-marca8 !important;
}

.swal2-title {
  // white-space: nowrap;
  overflow: hidden;
  // text-overflow: ellipsis;
}
