#modal-container {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  overflow: hidden;

  #modal {
    max-width: 100vw;
    /* max-height: 90vh; */
    border-radius: 10px;
    box-shadow: 0px 0px 6px 0px rgba(34, 66, 108, 0.15);
    padding: 30px;
    margin: 0 5px;
    background-color: #fff;

    .header {
      background-color: transparent;

      > .closse {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        button {
          background-color: transparent;
          border-radius: 50%;
          color: #2E3A59;
          position: relative;
          right: -10px;
          top: -11px;
          width: 50px;
          height: 50px;

          &:hover {
            background-color: #f5f5f5;
            transition: 0.5s ease;
          }
        }
      }
    }
  }

  .title {
    margin-bottom: 20px;
  }

  .body {
    max-height: 80%;
    overflow-y: auto;
    z-index: 1;
  }
}

.modal-flow-table {
  .modal-dialog {
    display: block;
    max-width: 100%;
    max-height: 100%;
    margin: 0;

    .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0;

      app-megaleios-modal {
        padding: 0;
      }
    }
  }
}
