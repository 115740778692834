.btn-danger {
  background: $vermelho;
  color: #fff;
  cursor: pointer;
}

.btn-transparent {
  background-color: transparent;
  color: rgb(82, 97, 117);;
}

.btn-success {
  background: $cor-marca2;
  color: $cor-marca1;
}

.btn-primary {
  background: $cor-marca1;
}

.btn-azul {
  background: $cor-marca3;
}

.btn-block {
  width: 100%;
}

.btn-rounded {
  font-size: 14px;
  font-weight: 500;
  color: #296FCC;
  border: 1px solid #296FCC;
  border-radius: 32px;
}

.btn-criar {
  border: 1px solid $cor-marca3;
  color: $cor-marca3;
  font-weight: 500;
  background-color: transparent;
  padding: 9px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  img {
    width: 24px;
    height: 24px;
    margin-right: 15px;
  }
}

.btn-branco {
  background-color: #fff !important;
  color: rgb(82, 97, 117);;
}
