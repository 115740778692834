.pa {
  padding: auto;
}

@for $padding from 0 through 100 {
  .pt-#{$padding} {
    padding-top: #{$padding}px;
  }

  .pl-#{$padding} {
    padding-left: #{$padding}px;
  }

  .pr-#{$padding} {
    padding-right: #{$padding}px;
  }

  .pb-#{$padding} {
    padding-bottom: #{$padding}px;
  }
}
