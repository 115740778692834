.box-esa {
  background-color: #fff;
  margin: 0 auto;
  box-shadow: 0 0 6px 0 rgba(34, 66, 108, 0.08);
  padding: 35px;
}

.cursor {
  cursor: pointer;
}
