button, .btn {
  padding: 12px;
  background: rgb(34, 66, 108);
  color: #FFF;
  cursor: pointer;
  border-width: 0;
  border-radius: 3px;
  border: none;
  outline: none !important;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &:disabled {
    background-color: #F2F7FB;
    color: rgb(34, 66, 108);
    cursor: not-allowed;
  }
}
