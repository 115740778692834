body {
  font-family: 'Roboto', 'MonstSerrat', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue', sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background-color: rgb(247, 248, 250);
  font-size: 100%;
  vertical-align: baseline;
  overflow-x: hidden;
}

html, body {
  min-height: 100vh;
  color: rgb(34, 66, 108);
}

a {
  color: rgb(41, 111, 204);
  text-decoration: none;
  word-wrap: break-word;
}

p, div, h1, h2, h3, h4, h5, form, a, ul, ol, li, img, label, span, i, small {
  margin: 0;
  padding: 0;
  background-color: transparent;
}

label {
  font-size: 14px;
  font-weight: 500;
  color: rgb(82, 97, 117);
}

h1, h2, h3, h4, h5 {
  color: rgb(82, 97, 117);
}

h1 {
  font-size: 24px;
  font-weight: bold;
}

h2 {
  font-size: 18px;
}

h3 {
  font-size: 16px;
}

img {
  max-width: 100%;
}

td {
  vertical-align: middle !important;
}
