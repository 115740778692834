.btn-table {
  font-size: 14px;
  color: #33b5e7;
  background: #333333;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 3px;

  &:hover {
    color: #333333;
    background: #33b5e7;
  }
}

tr .btn-table-hide {
  opacity: 0;
  transition: 0.5s;
}
