input[type='radio'] {
  width: 24px;
  height: 24px;
  font-weight: 100;
  background-color: $cor-marca3;
  border-radius: 10px;
  padding: 0;

  & + label {
    display: inline-table;
    position: relative;
    cursor: pointer;
    padding: 0px 10px 0px 10px;
    min-height: 20px;
  }
}
