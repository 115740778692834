@media only screen and (max-width: 1200px) {
  // ============ FORM ============ //
  // Forms de um único campo
  .input-integrado * {
    flex: 0 0 100%;
    padding: 10px;
    font-size: 16px;
  }

  .input-integrado select {
    border: none;
    border-top: solid 1px $escala-cinza-7;
    border-bottom: solid 1px $escala-cinza-7;
  }

  // Forms para Filtros
  .filter-inline select {
    max-width: calc(100% - 10px);
    flex: 0 0 100%;
  }

  .filter {
    flex-wrap: wrap-reverse;

    div:last-child,
    div:first-child {
      text-align: center;
      width: 100%;
      margin: 5px 0;
    }

    input,
    select {
      flex: 0 0 auto;
    }
  }

  // ============ TABLE ============ //
  .table > table {
    min-width: 1000px;
  }

  // ============ PAGINATION ============ //
  .pagination ul li:not(.link-page) {
    flex: 0 0 100%;
    text-align: right;
    margin: 10px 0;
  }
}
