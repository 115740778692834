.document {
  background-color: #FFF;
  padding: 32px;
  box-shadow: 0 0 8px #D3D9E2;
}

.document-company-logo {
  width: 132px;
  height: 132px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid #E3E4E6;
  border-radius: 4px;
}

.document-title-container {
  width: 100%;
  font-size: 18px;
  font-weight: 600;
}

.button-table {
  display: flex;
  align-items: center;
  background-color: transparent;
  font-weight: 500;
  color: #296FCC;
  line-height: 1;
  border: 1px solid #296FCC;

  > div {
    margin: 0 4px;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.flow-table-nav {
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: 1;
  background-color: #fff;
  display: flex;
  align-items: center;
  line-height: 1;
  padding: 16px;
  border-bottom: 1px solid #526175;

  > div {
    margin: 0 8px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.flow-table-nav-back {
  background-color: transparent;
  padding: 4px;
}

.flow-table-content {
  margin-top: 75px;
  max-height: 100%;
}

.flow-table-table {
  width: 100%;
  color: #000;

  tr {
    text-align: justify;

    &:first-child {
      font-weight: 500;
      text-align: center;

      td {
        border: 1px solid #526175;

        &:first-child {
          background-color: #FFF;
        }

        &:not(:first-child) {
          background-color: #F7F8FA;
          font-weight: 500;
        }
      }
    }

    td {
      min-width: 271px;
      padding: 16px;
      border: 1px solid #526175;

      input {
        height: 100%;
        background-color: transparent;
        font-weight: 400;
        color: #000;
        padding: 16px;
        border: 0;
        border-radius: 0;

        &.input {
          padding: 16px;
        }

        .search-results-container {
          top: auto;
          bottom: 100%;
        }
      }

      &:first-child {
        background-color: #F7F8FA;

        input {
          font-weight: 500;
        }
      }
    }
  }
}

.flow-table-cell-container {
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    margin: 0 4px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.flow-table-cell-button-container {
  visibility: hidden;
}

.flow-table-cell-button {
  background-color: transparent;
  line-height: 1;
  padding: 4px;
}

.flow-table-button-plus {
  background-color: #296FCC;
  font-weight: 600;
  line-height: 1;
  padding: 4px 8px;
}

td:hover {
  .flow-table-cell-button-container {
    visibility: visible;
  }
}

.steps-empty {
  padding: 16px;
  background-color: #F7F8FA;
  margin-bottom: 16px;
}

.step {
  background-color: #F7F8FA;
  padding: 16px;
  margin-bottom: 16px;
}

.step-header {
  color: #000000;
  margin-bottom: 16px;
}
