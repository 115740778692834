#blog-post {
  $cinza: rgb(82, 97, 117);
  $azul-escuro: rgb(6, 59, 117);

  .img-principal {
    width: 100%;
    max-height: 32.375em;
    object-fit: cover;
    margin: 0 auto;
  }

  .box-redes-sociais {
    margin-top: 4em;
    display: flex;
    flex-direction: column;

    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
      margin-bottom: 0 0 1em 0;
      cursor: pointer;
    }
  }

  .col-8 {
    text-align: left;

    h1, h2 {
      color: $azul-escuro;
      font-weight: bold;
      margin-bottom: 1.5em;
    }

    h1 {
      font-size: 1.3125em;
      letter-spacing: 2px;
    }

    h2 {
      font-size: 1.125em;
    }

    p {
      color: $cinza;
      font-size: 1em;
      line-height: 1.5em;
    }

    .data-post {
      color: $cinza;
      margin-top: 1em;
    }

    h1, h2, .data-post, img, p {
      margin-bottom: 1.5em;
    }

    .data-post {
      margin-top: 1em;
    }

    figure {
      img {
        max-height: 20.1875em;
        width: 100%;
        margin: 0 auto;
        object-fit: cover;
      }
    }
  }
}
