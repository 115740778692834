/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import '~bootstrap/dist/css/bootstrap-reboot.min.css';
@import '~bootstrap/dist/css/bootstrap-grid.min.css';

@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css';
@import 'stylus/global.scss';

// Definir variáveis em SCSS
$SM: 576px;
$MD: 768px;
$LG: 992px;
$XL: 1200px;

.section {
  padding: 32px 17px;
  @media screen and (min-width: 768px) {
    padding: 64px 49px;
  }
}

.modal-backdrop {
  z-index: 1040 !important;
}

h1 {
  font-size: 24px;
  font-weight: bold;
}
