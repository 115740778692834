// Preto e branco
$escala-cinza-1: #ffffff;
$escala-cinza-2: #f5f5f5;
$escala-cinza-3: #e5e5e5;
$escala-cinza-4: #d1d1d1;
$escala-cinza-5: #b2b2b2;
$escala-cinza-6: #999999;
$escala-cinza-7: #808080;
$escala-cinza-8: #666666;
$escala-cinza-9: #4d4d4d;
$escala-cinza-10: #333333;
$escala-cinza-11: #1a1e24;
$escala-cinza-12: #030c16;
$escala-cinza-13: #000000;

// Cores Padrões Bootstrap
$vermelho: #ff3548;
$vermelho-escuro: #cc0001;
$verde: #009d25;
$turqueza: #2abbac;
$turqueza-escuro: #00695b;
$amarelo: #ffbb34;
$amarelo-escuro: #fe9003;
$amarelo-queimado: #f9c700;
$azul-claro: #7171ff;
$azul: #33b5e7;
$azul-escuro: #05009f;
$roxo: #aa66cd;
$roxo-escuro: #9a33cc;

$cinza-1: rgb(247, 248, 250);
$cinza-2: rgba(34, 111, 173, 0.06);
$cinza-3: rgb(187, 193, 201);
$cinza-4: rgb(82, 97, 117);
$cinza-5: rgb(107, 127, 153);
$cinza-6: rgb(227, 228, 230);

$branco: #fff;
$cor-marca1: rgb(34, 66, 108);
$cor-marca2: rgb(79, 232, 144);
$cor-marca3: rgb(41, 111, 204);
$cor-marca4: rgb(6, 59, 117);
$cor-marca5: rgb(73, 230, 143);
$cor-marca6: rgb(57, 164, 129);
$cor-marca7: rgba(255, 205, 210, 0.5);
$cor-marca8: #FF5722;
$cor-marca9: rgb(70, 207, 128);
$cor-marca10: #2196F3;

$cor-marca-azul: #063B75;
$cor-marca-verde: #49E68F;
$cor-marca-verde-escuro: #39A481;
$cor-marca-azul1: #296FCC;
$cor-marca-azul2: #2196F3;
$cor-marca-azul3: #3F51B5;
$cor-marca-roxo: #6200EA;
$cor-marca-roxo-escuro: #410397;
$cor-marca-laranja: #FF5722;
$cor-marca-verde1: #00C853;
$cor-marca-amarelo: #FB8C00;

$cor-luz: #F2F7FB;

$border-hover-azul: 2px solid rgb(41, 111, 204);
